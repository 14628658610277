<template>
  <div class="view view--company view--company-security">
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s1.tit[0]" />
            <h1 class="tit fw-500 fc-dark ts-36 ts-md-48 mb-4" v-html="cc.s1.tit[1]" />
            <p class="txt fw-400 fc-gray mb-3" v-html="cc.s1.txt" />
          </div>
          <div class="col-12 col-lg-6">
            <div class="img img--company-security">
              <img src="/img/views/img-company-security.png" alt="security" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-3 mb-md-0"
               v-for="(card, c) in cc.s2.cards" :key="`card-item-${c}`">
            <comp-card comp="t7" :data="card" />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 mb-3" v-html="cc.s3.tit[0]" />
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 mb-5" v-html="cc.s3.tit[1]" />
            <comp-card v-for="(card, c) in cc.s3.cards" :key="`card-item-${c}`"
                       comp="t8" :data="card" />
          </div>
          <div class="col-12 col-lg-6 col-xl-5 offset-xl-1">
            <comp-img type="parallax5"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--platform-alleviate">
              <div class="row">
                <div class="col-12 col-lg-8">
                  <h2 class="tit fw-500 fc-white ts-32 mb-4" v-html="alleviate.tit" />
                  <p class="txt fw-400 fc-white ts-18" v-html="alleviate.txt" />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="wrapper wrapper--v-end">
                    <btn :data="alleviate.btn" :theme="{type: 3, size: 'md', width: 174}" class="ms-md-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompCard from '@/components/Card'
import CompImg from '@/components/Img'
import Btn from '@/components/Btn'
export default {
  components: {
    CompCard, CompImg, Btn
  },
  computed: {
    cc () {
      return this.$t('views.company.security')
    },
    alleviate () {
      return this.$t('components.alleviate')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.company.security')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
